import { assertEx } from '@xylabs/assert'
import { forget } from '@xylabs/forget'
import React from 'react'
import {
  createRoot,
// eslint-disable-next-line import-x/no-internal-modules
} from 'react-dom/client'

import { App } from './app/index.ts'
import * as serviceWorker from './serviceWorker.ts'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (import.meta.env.VITE_USE_BIOS === 'true') {
  serviceWorker.register()
} else {
  forget(serviceWorker.unregister())
}

const root = createRoot(assertEx(document.querySelector('#root')))

root.render(<App />)
